import {MiddlewareAction} from "../middleware";
import { CreateEmployee, PendingApprovalEntityType, PendingApprovalStatus, UpdateEmployee, WorkerType } from "../models";
import {EmployerAdminDto} from "../dto";
import {VendorAction} from "./vendor";

export enum Actions {
    REQUEST_ADD_EMPLOYEE = "REQUEST_ADD_EMPLOYEE",
    REQUEST_UPDATE_EMPLOYEE = "REQUEST_UPDATE_EMPLOYEE",
    REQUEST_CHECK_CREATE_EMPLOYEE_FIELDS = "REQUEST_CHECK_CREATE_EMPLOYEE_FIELDS",
    REQUEST_GET_EMPLOYEES = "REQUEST_GET_EMPLOYEES",
    REQUEST_ADD_EMPLOYEE_IN_BULK = "REQUEST_ADD_EMPLOYEE_IN_BULK",
    REQUEST_GET_EMPLOYEE_BULK_UPLOAD_HISTORY = "REQUEST_GET_EMPLOYEE_BULK_UPLOAD_HISTORY",
    REQUEST_GET_EMPLOYEE_BULK_UPLOAD_PROCESSED_DOC = "REQUEST_GET_EMPLOYEE_BULK_UPLOAD_PROCESSED_DOC",
    REQUEST_REMOVE_EMPLOYEE = "REQUEST_REMOVE_EMPLOYEE",
    REQUEST_REMOVE_EMPLOYEE_IN_BULK = "REQUEST_REMOVE_EMPLOYEE_IN_BULK",
    REQUEST_TERMINATE_EMPLOYEES = "REQUEST_TERMINATE_EMPLOYEES",
    REQUEST_ACTIVATE_EMPLOYEES = "REQUEST_ACTIVATE_EMPLOYEES",
    REQUEST_EMPLOYEE_TRANSACTIONS = "REQUEST_EMPLOYEE_TRANSACTIONS",
    REQUEST_EMPLOYEE_DETAILS = "REQUEST_EMPLOYEE_DETAILS",
    REQUEST_USER_DOCUMENT = "REQUEST_USER_DOCUMENT",
    REQUEST_ALL_EMPLOYEES_TRANSACTION = "REQUEST_ALL_EMPLOYEES_TRANSACTION",
    REQUEST_ALL_EMPLOYEES_ADVANCE_SALARIES = "REQUEST_ALL_EMPLOYEES_ADVANCE_SALARIES",
    REQUEST_EMPLOYER_ADMIN_LIST = "REQUEST_EMPLOYER_ADMIN_LIST",
    REQUEST_CREATE_EDIT_ADMIN = "REQUEST_CREATE_EDIT_ADMIN",
    REQUEST_REMOVE_ADMIN = "REQUEST_REMOVE_ADMIN",
    REQUEST_EMPLOYER_ADMIN_PENDING_WORKER_APPROVALS = "REQUEST_EMPLOYER_ADMIN_PENDING_WORKER_APPROVALS",
    REQUEST_EMPLOYER_ADMIN_PENDING_TRANSACTION_APPROVALS = "REQUEST_EMPLOYER_ADMIN_PENDING_TRANSACTION_APPROVALS",    
    REQUEST_EDIT_EMPLOYEE_PENDING_APPROVAL = "REQUEST_EDIT_EMPLOYEE_PENDING_APPROVAL",
}
export function addEmployee(
    payload: CreateEmployee
): MiddlewareAction {
    return {
        actionName: Actions.REQUEST_ADD_EMPLOYEE,
        type: "POST",
        url: "/employer-admin/employee",
        body: payload
    }
}


export function updateEmployee(
    payload: UpdateEmployee
): MiddlewareAction {
    return {
        actionName: Actions.REQUEST_UPDATE_EMPLOYEE,
        type: "PATCH",
        url: "/employer-admin/employee",
        body: payload
    }

}

export function checkCreateEmployeeFields(payload: { email?: string; mobile?: string; employeeId?: string }): MiddlewareAction {
    return {
        actionName: Actions.REQUEST_CHECK_CREATE_EMPLOYEE_FIELDS,
        type: "POST",
        url: "/employer-admin/employee-field-check",
        body: payload
    }
}


export function getEmployees(vendorId: string | null): MiddlewareAction {
    let params = {
        vendorId,
    }
    //@ts-ignore
    if (!vendorId) delete params.vendorId;
    const actionName = vendorId ? VendorAction.REQUEST_GET_VENDOR_EMPLOYEES : Actions.REQUEST_GET_EMPLOYEES
    return {
        actionName,
        type: "PUT",
        url: "/employer-admin/search",
        params,
        body: {
            "filters": [
                "string"
            ],
            "sortby": [
                "string"
            ]
        }
    }
}

export function addEmployeeInBulk(payload: FormData, vendorId: string | null): MiddlewareAction {
    const params = {
        vendorId,
    }
    //@ts-ignore
    if (!vendorId) delete params.vendorId;
    return {
        actionName: Actions.REQUEST_ADD_EMPLOYEE_IN_BULK,
        type: "POST",
        url: "/v2/employer-admin/upload-employee-doc",
        params,
        contentType: "multipart/form-data",
        body: payload,
    }
}

export function getEmployeeBulkUploadHistory(vendorId?: string): MiddlewareAction {
    return {
        actionName: Actions.REQUEST_GET_EMPLOYEE_BULK_UPLOAD_HISTORY,
        type: "GET",
        url: "/v2/employer-admin/employee-uploaded-doc-history",
        params: { vendorId }
    }
}

// INFO: GET - /employer-admin/doc is there at multiple actions
// TODO: Make a generic action to handle that
export function getEmployeeBulkUploadProcessedDoc(payload: {
    employerAdminId: string;
    documentId: string;
    documentType: string;
}): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_EMPLOYEE_BULK_UPLOAD_PROCESSED_DOC,
    type: "GET",
    url: "/employer-admin/doc",
    params: payload,
  };
}

export function removeEmployee(employeeId: string): MiddlewareAction {
    return {
        actionName: Actions.REQUEST_REMOVE_EMPLOYEE,
        type: "PATCH",
        url: "/employer-admin/terminate-employee",
        body: {
            employeeId
        },
    }
}

export function removeEmployeesInBulk(b64File: string, vendorId: string | null): MiddlewareAction {
    const params = {
        vendorId,
    }
    //@ts-ignore
    if (!vendorId) delete params.vendorId;
    return {
        actionName: Actions.REQUEST_REMOVE_EMPLOYEE_IN_BULK,
        type: "POST",
        url: "/employer-admin/upload-employee-termination-doc",
        params,
        body: {
            file: b64File,
        }
    }
}

export function getAnEmployeeTransactions(empId: string) {
    return {
        actionName: Actions.REQUEST_EMPLOYEE_TRANSACTIONS,
        type: "GET",
        url: `/employer-admin/transactions?employeeId=${empId}`,
    }
}
export function getEmployeeDetails(empId: string) {
    return {
        actionName: Actions.REQUEST_EMPLOYEE_DETAILS,
        type: "GET",
        url: `/employer-admin/user?employeeId=${empId}`,
    }
}

export function getUserDoc(employeeId: string, docType: string) {
    return {
        actionName: Actions.REQUEST_USER_DOCUMENT,
        type: "GET",
        url: `/employer-admin/user-document?employeeId=${employeeId}&docType=${docType}`,
    }
}
export function getAllEmployeesTransaction(startDate: Date, endDate: Date, vendorId?: string | null): MiddlewareAction {
    const startDateAsIsoString = startDate.toISOString();
    const endDateAsIsoString = endDate.toISOString();
    const params = {
        startDate: startDateAsIsoString,
        endDate: endDateAsIsoString,
        vendorId,
    }
    if (!vendorId) delete params.vendorId;
    return {
        actionName: Actions.REQUEST_ALL_EMPLOYEES_TRANSACTION,
        type: "GET",
        url: "/employer-admin/get-transactions-of-employees",
        params
    }
}

export function getAllEmployeesAdvanceSalaries(startDate: Date, endDate: Date): MiddlewareAction {
    const startDateAsIsoString = startDate.toISOString();
    const endDateAsIsoString = endDate.toISOString();
    const params = {
        startDate: startDateAsIsoString,
        endDate: endDateAsIsoString,
    }
    return {
        actionName: Actions.REQUEST_ALL_EMPLOYEES_TRANSACTION,
        type: "GET",
        url: "/employer-admin/salary-topup/applications",
        params
    }
}

export function getEmployerAdmins(vendorId?: string | null) {
    const params = {vendorId}
    if (!vendorId) delete params.vendorId
    return {
        actionName: vendorId ? VendorAction.REQUEST_GET_VENDOR_ADMINS : Actions.REQUEST_EMPLOYER_ADMIN_LIST,
        type: "GET",
        url: `/employer-admin/employer-admins`,
        params
    }
}

export function createOrEditAdmin(body: EmployerAdminDto, isAdminEdit: boolean, id: string): MiddlewareAction {
    const params = {id}
    //@ts-ignore
    if (!id) delete params.id;
    const url =
        isAdminEdit
        ? "/employer-admin/update-employer-admin"
        : "/employer-admin/create-employer-admin"
    return {
        actionName: Actions.REQUEST_CREATE_EDIT_ADMIN,
        type: isAdminEdit ? "PUT" : "POST",
        url,
        body,
        params
    }
}

export function removeAdmin(id: string): MiddlewareAction {
    return {
        actionName: Actions.REQUEST_REMOVE_ADMIN,
        type: "DELETE",
        url: `/employer-admin/terminate/${id}`,
    }
}

const ENTITY_TYPE_TO_WORKER_ACTION_NAME: Record<PendingApprovalEntityType, Actions> = {
    'TRANSACTION' : Actions.REQUEST_EMPLOYER_ADMIN_PENDING_TRANSACTION_APPROVALS,
    'WORKER': Actions.REQUEST_EMPLOYER_ADMIN_PENDING_WORKER_APPROVALS
}

export function getEmployeerPendingWorkerApprovals(entityType: PendingApprovalEntityType): MiddlewareAction {
    return {
        actionName: ENTITY_TYPE_TO_WORKER_ACTION_NAME[entityType],
        type: "GET",
        url: `/employer-admin/employer-approval/pending-approvals?entityType=${entityType}`,
    }
}

export function employeeInfoApproval(payload:
    {entityId: string, entityType: PendingApprovalEntityType, status: PendingApprovalStatus, modifiedFields?: Record<string, string | number> }):MiddlewareAction{
    return {
        actionName: Actions.REQUEST_EDIT_EMPLOYEE_PENDING_APPROVAL ,
        type: "PUT",
        url: "/employer-admin/employer-approval/update-request",
        body: payload,
    }
}
export function terminateEmployees(employerEmployeeIds: string[]): MiddlewareAction {
    return {
        actionName: Actions.REQUEST_TERMINATE_EMPLOYEES,
        type: "PATCH",
        url: "/v2/employer-admin/terminate-employees",
        body: { employerEmployeeIds }
    }
}

export function activateEmployees(employerEmployeeIds: string[]): MiddlewareAction {
    return {
        actionName: Actions.REQUEST_ACTIVATE_EMPLOYEES,
        type: "PATCH",
        url: "/v2/employer-admin/activate-employees",
        body: { employerEmployeeIds }
    }
}
