import React, { useEffect, useMemo, useState } from "react";
import dispatch from "../../../middleware";
import { getEmployees } from "../../../actions/employee";
import { useDispatch, useSelector } from "react-redux";
import {
  EmployeeDetails,
  EmployeeEmploymentStatus,
  EmployerMetadata,
  RoutePaths,
} from "../../../models";
import { ReduxState } from "../../../reducers";
import Modal from "../../../components/modal";
import AddEmployeeForm from "./addEmployeeForm";
import FullScreenLoader from "../../../components/fullscreen-loader";
import "./usersStyles.css";
import {
  chainFilterPredicateByAND,
  downloadExcelFromArrayOfObjects,
  generateSortComparator,
  getFilterPredicateForSearchText,
  getParamFromUrl,
  navigateTo,
  paginate,
} from "../../../utils";
import getEffect from "../effect";
import Authorized from "../../../authorization/authorized";
import {
  AccessType,
  Resource,
} from "../../../authorization/authorization.enum";
import isAuthorized from "../../../authorization/authorizationAccess";
import { logEventInFirebase } from "../../../analytics/firebase.analytics";
import AnalyticsEvent from "../../../analytics/events";
import { Drawer } from "../../../components/library/drawer";
import { Table, TableColumn } from "../../../components/library/table";
import Tag from "../../../components/library/tag";
import { IconButton } from "../../../components/library/icon-button";
import {
  FunnelIcon,
  PencilSimpleLineIcon,
  PlusIcon,
  SquareHalfIcon,
} from "../../../components/library/icon";
import Pagination from "../../../components/library/pagination";
import { Popover } from "../../../components/library/popover";
import Filter, {
  FilterMetadata,
  FilterState,
} from "../../../components/library/filter";
import { InlineInputGroup } from "../../../components/library/inline-input-group";
import { Switch } from "../../../components/library/switch";
import { Button } from "../../../components/library/button";
import { SearchInput } from "../../../components/library/search-input";
import { SortOrder } from "../../../components/library/table/sort-button";
import { UnstyledButton } from "../../../components/library/unstyled-button";
import { useTableState } from "../../../components/library/table/use-table-state";
import EmployeeActionModalContent from "./employeeActionModalContent";

enum EmployeeStatus {
  SIGNED_UP = "Signed Up",
  NOT_SIGNED_UP = "Not Signed Up",
}

function getTableFilterMetadata(
  employerMetadata: EmployerMetadata | null
): FilterMetadata[] {
  return [
    {
      entity: {
        name: "status",
        label: "Refyne Status",
      },
      values: Object.values(EmployeeStatus).map((value) => ({ name: value })),
    },
    {
      entity: {
        name: "employmentStatus",
        label: "Employment Status",
      },
      values: Object.values(EmployeeEmploymentStatus).map((value) => ({
        name: value,
      })),
    },
    ...(!!employerMetadata?.branchIds.length
      ? [
          {
            entity: {
              name: "branchId",
              label: "Branch",
            },
            values: employerMetadata.branchIds.map((branch) => ({
              name: branch,
            })),
          },
        ]
      : []),
    ...(!!employerMetadata?.gradeIds.length
      ? [
          {
            entity: {
              name: "gradeId",
              label: "Grade",
            },
            values: employerMetadata.gradeIds.map((grade) => ({ name: grade })),
          },
        ]
      : []),
    ...(!!employerMetadata?.departmentIds.length
      ? [
          {
            entity: {
              name: "departmentId",
              label: "Department",
            },
            values: employerMetadata.departmentIds.map((department) => ({
              name: department,
            })),
          },
        ]
      : []),
  ];
}

function getFilterPredicateForTableFilter<T>(
  filters: FilterState[]
): (value: T) => boolean {
  return (value) => {
    const result = filters.reduce<boolean | null>((previousValue, current) => {
      const {
        joinOperator = "AND",
        leftOperand,
        operator,
        rightOperand,
      } = current;

      if (joinOperator === "AND" && previousValue === false) return false;
      if (joinOperator === "OR" && previousValue === true) return true;

      if (!leftOperand || !operator || !rightOperand) return false;
      const fieldValue: unknown = value[leftOperand as keyof T];

      if (operator === "==") return fieldValue === rightOperand;
      if (operator === "!=") return fieldValue !== rightOperand;

      return false;
    }, null);

    if (result === null) return true;
    return result;
  };
}

function renderDownloadButton(
  selectedRows: FormattedEmployeeDetails[],
  onClick: (selectedRows: FormattedEmployeeDetails[], downloadAll: boolean) => void
) {
  const count = selectedRows.length;
  return (
    <Button
      size="sm"
      onClick={() => onClick(selectedRows, count < 1)}
    >
      Download {count > 0 ? `(${count})` : ""}
    </Button>
  );
}

function renderActiveInactiveButton(
  selectedRows: FormattedEmployeeDetails[],
  onClick: (ctx: {
    action: "ACTIVATE" | "TERMINATE";
    data: FormattedEmployeeDetails[];
  }) => void
) {
  const count = selectedRows.length;
  const isOnlyInactiveSelected = !selectedRows.some(
    (val) => val.employmentStatus !== EmployeeEmploymentStatus.INACTIVE
  );
  const countLabel = count > 0 ? `(${count})` : ""

  if (count > 0 && isOnlyInactiveSelected) {
    return (
      <Button
        variant="secondary"
        color="success"
        size="sm"
        onClick={() => onClick({ action: "ACTIVATE", data: selectedRows })}
      >
        Activate {countLabel}
      </Button>
    );
  }

  const isAnyInactiveSelected = selectedRows.some(
    (val) => val.employmentStatus === EmployeeEmploymentStatus.INACTIVE
  );
  const isDisabled = count < 1 || isAnyInactiveSelected;
  return (
    <Button
      variant="secondary"
      color="danger"
      size="sm"
      disabled={isDisabled}
      onClick={() =>
        onClick({
          action: "TERMINATE",
          data: selectedRows,
        })
      }
    >
      Terminate {isDisabled ? "" : countLabel}
    </Button>
  );
}

function renderPaginationText(
  total: number,
  page: number,
  perPageCount: number
) {
  const start = perPageCount * (page - 1) + 1;
  const end = start + perPageCount - 1;
  const endCount = end > total ? total : end;
  return `${start} - ${endCount} of ${total}`;
}

function renderColumnToggler<T>(
  toggleableColumns: Array<{
    key: keyof T;
    label: React.ReactNode;
    visible: boolean;
    disabled: boolean;
  }>,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onAllToggle: (action: "SHOW" | "HIDE") => void
) {
  const isAllSelected = toggleableColumns.every(col => col.visible);

  return (
    <div className="pt-2 pb-3 px-2">
      <div className="d-flex align-items-center justify-content-between mb-4">
        <p className="m-0">COLUMNS</p>
        <UnstyledButton onClick={() => onAllToggle(isAllSelected ? "HIDE" : "SHOW")}>
          <span className="small">{isAllSelected ? "Hide" : "Select"} All</span>
        </UnstyledButton>
      </div>
      <InlineInputGroup orientation="vertical" style={{ width: "240px" }} spacing="20px">
        {toggleableColumns.map((column) => (
          <Switch
            key={column.key.toString()}
            label={column.label ?? column.key}
            labelPosition="left"
            value={column.key.toString()}
            checked={column.visible}
            onChange={onChange}
            disabled={column.disabled}
            size="md"
            fullWidth
          />
        ))}
      </InlineInputGroup>
    </div>
  );
}

interface FormattedEmployeeDetails extends EmployeeDetails {
  status: EmployeeStatus;
}
export function formatEmployeeData(
  employees: EmployeeDetails[],
  searchText: string,
  filters: FilterState[],
  sort?: { by: keyof FormattedEmployeeDetails; order: SortOrder }
): FormattedEmployeeDetails[] {
  const modifiedEmployeeData = employees
    .map<FormattedEmployeeDetails>((employee) => ({
      ...employee,
      status: employee.userId
        ? EmployeeStatus.SIGNED_UP
        : EmployeeStatus.NOT_SIGNED_UP,
    }))
    .filter(
      chainFilterPredicateByAND(
        getFilterPredicateForTableFilter(filters),
        getFilterPredicateForSearchText(
          searchText,
          ["fullName", "employerEmployeeId", "mobile", "email"],
          { separator: "," }
        )
      )
    );
  if (!sort || sort.order === "none") return modifiedEmployeeData;
  return modifiedEmployeeData.sort(
    generateSortComparator(
      sort.by,
      sort.order,
      sort.by === "currentMonthlySalary" ? "number" : "string"
    )
  );
}

const ITEMS_PER_PAGE = 10;

export function CurrentEmployees() {
  const storeDispatch = useDispatch();
  const vendorId = getParamFromUrl("vendorId");
  const authResource = vendorId
    ? Resource.EMPLOYER_PORTAL_VENDORS_EMPLOYEES
    : Resource.EMPLOYER_PORTAL_EMPLOYEES;
  useEffect(getEffect("Employees", useDispatch()), []);
  const employerMetadata = useSelector(
    (state: ReduxState) => state.employer.employerMetadata
  );
  const employees: EmployeeDetails[] = useSelector((state: ReduxState) =>
    vendorId ? state.employee.vendorEmployees : state.employee.employees
  );
  const loading: boolean = useSelector(
    (state: ReduxState) => state.employee.loading
  );
  const resources = useSelector(
    (state: ReduxState) => state.login.userDetails?.resources || {}
  );

  const [filterPopoverActive, setFilterPopoverActive] = useState(false);
  const [columnPopoverActive, setColumnPopoverActive] = useState(false);

  const [userInputToBeEdited, updateUserInputToBeEdited] =
    useState<EmployeeDetails | null>(null);
  const [isAddEmployeeModalActive, toggleAddEmployeeModal] =
    useState<boolean>(false);
  const [isEmployeeSignedUp, setIsEmployeeSignedUp] = useState(false);
  const [actionModal, setActionModal] = useState<{
    action: "TERMINATE" | "ACTIVATE";
    data: FormattedEmployeeDetails[];
  } | null>(null);

  const DATATABLE_COLUMNS: TableColumn<FormattedEmployeeDetails>[] = useMemo(
    () => [
      {
        label: "Full Name",
        key: "fullName",
        sortable: true,
        render: renderEmployeeName,
        rowActionButtonsVisibleOn: "hover",
        rowActionButtons: (row) => (
          <IconButton
            title="Edit"
            spacing="compact"
            color="brand"
            variant="light"
            onClick={() => {
              updateUserInputToBeEdited(row);
              setIsEmployeeSignedUp(row.status === EmployeeStatus.SIGNED_UP);
              toggleAddEmployeeModal(true);
            }}
          >
            <PencilSimpleLineIcon size="sm" ariaHidden />
          </IconButton>
        ),
        width: "180px",
      },
      {
        label: "Employee ID",
        key: "employerEmployeeId",
        sortable: true,
        // INFO: Added a style override to stick upto this column
        // In we change the width, please change the offset in css as well
        width: "150px",
      },
      {
        label: "Email ID",
        key: "email",
        sortable: true,
        width: "180px",
      },
      {
        label: "Mobile No.",
        key: "mobile",
        sortable: true,
        width: "100px",
      },
      {
        label: "Refyne Status",
        key: "status",
        render: (status) => (
          <Tag
            indicatorPosition="left"
            color={status === EmployeeStatus.SIGNED_UP ? "success" : "alert"}
          >
            {status}
          </Tag>
        ),
        sortable: true,
        width: "120px",
        align: "center",
      },
      {
        label: "Employment Status",
        key: "employmentStatus",
        render: (status) => (
          <Tag
            indicatorPosition="left"
            color={
              status === EmployeeEmploymentStatus.ACTIVE
                ? "success"
                : status === EmployeeEmploymentStatus.IN_PROBATION
                ? "alert"
                : "grey"
            }
          >
            {status}
          </Tag>
        ),
        sortable: true,
        width: "180px",
        align: "center",
      },
      {
        label: "Monthly Salary",
        key: "currentMonthlySalary",
        sortable: true,
        width: "150px",
        align: "right"
      },
      ...(Boolean(employerMetadata?.branchIds.length)
        ? [
            {
              label: "Branch",
              key: "branchId" as keyof FormattedEmployeeDetails,
              sortable: true,
              width: "150px",
            },
          ]
        : []),
      ...(Boolean(employerMetadata?.gradeIds.length)
        ? [
            {
              label: "Grade",
              key: "gradeId" as keyof FormattedEmployeeDetails,
              sortable: true,
              width: "150px",
            },
          ]
        : []),
      ...(Boolean(employerMetadata?.departmentIds.length)
        ? [
            {
              label: "Department",
              key: "departmentId" as keyof FormattedEmployeeDetails,
              sortable: true,
              width: "150px",
            },
          ]
        : []),
    ],
    [employerMetadata]
  );

  const __formattedEmployees = useMemo(
    () =>
      employees.map<FormattedEmployeeDetails>((employee) => ({
        ...employee,
        status: employee.userId
          ? EmployeeStatus.SIGNED_UP
          : EmployeeStatus.NOT_SIGNED_UP,
      })),
    [employees]
  );

  const {
    currentPageNumber,
    setCurrentPageNumber,
    searchText,
    setSearchText,
    debouncedSearchText,
    toggleableColumns,
    toggleTableColumnVisibility,
    toggleAllTableColumnVisibility,
    tableFilters,
    setTableFilters,
    sortState,
    selectedRows,
    clearSelection,
    getTableProps,
  } = useTableState({
    initialState: {
      tableColumnVisibility: {
        fullName: {
          visible: true,
          disabled: true,
        },
        employerEmployeeId: {
          visible: true,
          disabled: true,
        },
        branchId: {
          visible: false
        },
        gradeId: {
          visible: false
        },
        departmentId: {
          visible: false
        }
      },
    },
    data: __formattedEmployees,
    columns: DATATABLE_COLUMNS,
    config: {
      hasRowSelection: true,
      hasSort: true,
      uniqueKey: "employerEmployeeId",
    },
  });

  const _formattedEmployees = useMemo(() => {
    const val = __formattedEmployees.filter(
      chainFilterPredicateByAND(
        getFilterPredicateForTableFilter(tableFilters),
        getFilterPredicateForSearchText(
          debouncedSearchText,
          ["fullName", "employerEmployeeId", "mobile", "email"],
          { separator: "," }
        )
      )
    );
    if (!sortState || sortState.order === "none") return val;
    return val.sort(
      generateSortComparator(
        sortState.by,
        sortState.order,
        sortState.by === "currentMonthlySalary" ? "number" : "string"
      )
    );
  }, [__formattedEmployees, tableFilters, debouncedSearchText, sortState]);

  const formattedEmployees = useMemo(
    () => paginate(_formattedEmployees, ITEMS_PER_PAGE, currentPageNumber),
    [_formattedEmployees, ITEMS_PER_PAGE, currentPageNumber]
  );

  function renderEmployeeName(name: string, rowObj: FormattedEmployeeDetails) {
    if (rowObj.status !== EmployeeStatus.SIGNED_UP) {
      return name;
    }
    const path = vendorId
      ? `/vendors/employeeProfile`
      : RoutePaths.EMPLOYEE_PROFILE;
    let query = `?empId=${rowObj._id}&employerEmployeeId=${rowObj.employerEmployeeId}`;
    if (rowObj.joinedAt) {
      query = `${query}&joiningDate=${rowObj.joinedAt}`;
    }
    if (vendorId) {
      query = `${query}&vendorId=${vendorId}`;
    }
    return (
      <span
        onClick={() => navigateTo(`${path}${query}`)}
        className="primarycolor cursor-pointer"
      >
        {rowObj.fullName}
      </span>
    );
  }

  const DATATABLE_FILTER_METADATA = useMemo(
    () => getTableFilterMetadata(employerMetadata),
    [employerMetadata]
  );

  useEffect(() => {
    if (
      !employees?.length &&
      isAuthorized(resources, authResource, AccessType.READ)
    ) {
      dispatch(storeDispatch, getEmployees(vendorId));
    }
  }, []);

  function handleDownloadClick(_data: FormattedEmployeeDetails[], downloadAll: boolean = false) {
    logEventInFirebase(AnalyticsEvent.DOWNLOAD_EMPLOYEE_DATA_OPTION_CLICKED, {
      vendorId,
    });
    const data = downloadAll ? _formattedEmployees : _data;
    if (data.length < 1) return;
    const employeesArr = data.map((employee) => {
      const obj: Record<string, string> = {};
      // Get all the fields shown on Table
      toggleableColumns.forEach((col) => {
        if (col.label && col.key && col.visible) {
          const fieldName = col.label.toString();
          obj[fieldName] = employee[col.key];
        }
      });
      return obj;
    });
    downloadExcelFromArrayOfObjects(employeesArr, "employee-report.xlsx");
  }

  function renderNotAccessible() {
    return (
      <div className={"d-flex justify-content-center align-items-center"}>
        You aren't authorized to access this page
      </div>
    );
  }

  function getBulkUploadEmployeePath() {
    let path = `${RoutePaths.DOCUMENT_UPLOAD}?document=BULK_ADD_EMP`;
    if (vendorId) path = `${path}&vendorId=${vendorId}`;
    return path;
  }
  function getBulkTerminateEmployeePath() {
    let path = `${RoutePaths.DOCUMENT_UPLOAD}?document=BULK_TERMINATE`;
    if (vendorId) path = `${path}&vendorId=${vendorId}`;
    return path;
  }
  return (
    <div>
      <FullScreenLoader active={loading} />
      <Authorized
        resourceName={authResource}
        requiredAccessType={AccessType.WRITE}
      >
        <div className="d-flex justify-content-between flex-md-row flex-column mb-4">
          <Button
            variant="primary"
            color="brand"
            size="md"
            leftSection={<PlusIcon size="md" />}
            style={{ minWidth: "216px" }}
            onClick={() => {
              updateUserInputToBeEdited(null);
              setIsEmployeeSignedUp(false);
              toggleAddEmployeeModal(true);
              logEventInFirebase(
                AnalyticsEvent.ADD_SINGLE_EMPLOYEE_OPTION_CLICKED,
                { vendorId }
              );
            }}
          >
            Add Employee
          </Button>
          <div className="d-flex mt-3 mt-md-0" style={{ gap: "16px" }}>
            <Button
              variant="secondary"
              color="brand"
              size="md"
              onClick={() => {
                logEventInFirebase(
                  AnalyticsEvent.ADD_BULK_EMPLOYEES_OPTION_CLICKED,
                  { vendorId }
                );
                navigateTo(getBulkUploadEmployeePath());
              }}
            >
              Bulk Upload
            </Button>
            <Button
              variant="secondary"
              color="danger"
              size="md"
              onClick={() => {
                logEventInFirebase(
                  AnalyticsEvent.TERMINATE_BULK_EMPLOYEES_OPTION_CLICKED,
                  { vendorId }
                );
                navigateTo(getBulkTerminateEmployeePath());
              }}
            >
              Bulk Delete
            </Button>
          </div>
        </div>
      </Authorized>

      <Authorized
        resourceName={authResource}
        requiredAccessType={AccessType.READ}
        unAuthorizedView={renderNotAccessible()}
      >
        <Table
          header={
            <div className="d-flex justify-content-between flex-lg-row flex-column">
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <SearchInput
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.currentTarget.value)}
                />
                <Popover
                  active={filterPopoverActive}
                  triggerElement={
                    <IconButton
                      title="Filter"
                      color="brand"
                      variant="light"
                      spacing="compact"
                    >
                      <FunnelIcon size="md" variant="regular" />
                      {tableFilters.length > 0 && (
                        <span style={{ marginLeft: "4px" }}>
                          {tableFilters.length}
                        </span>
                      )}
                    </IconButton>
                  }
                  onToggle={() => setFilterPopoverActive((prev) => !prev)}
                  position="bottom-right"
                >
                  <div style={{ width: "560px" }}>
                    <Filter
                      metadata={DATATABLE_FILTER_METADATA}
                      currentState={tableFilters}
                      onChange={setTableFilters}
                      onClose={() => setFilterPopoverActive((prev) => !prev)}
                    />
                  </div>
                </Popover>
                <Popover
                  active={columnPopoverActive}
                  triggerElement={
                    <IconButton
                      title="Toggle Column"
                      color="brand"
                      variant="light"
                      spacing="compact"
                    >
                      <SquareHalfIcon size="md" variant="fill" />
                    </IconButton>
                  }
                  onToggle={() => setColumnPopoverActive((prev) => !prev)}
                  position="bottom-right"
                >
                  {renderColumnToggler(
                    toggleableColumns,
                    toggleTableColumnVisibility,
                    toggleAllTableColumnVisibility
                  )}
                </Popover>
              </div>
              <div
                style={{ display: "flex", gap: "8px" }}
                className="mt-4 mt-lg-0"
              >
                {renderDownloadButton(selectedRows, handleDownloadClick)}
                <Authorized
                  resourceName={authResource}
                  requiredAccessType={AccessType.WRITE}
                >
                  {renderActiveInactiveButton(selectedRows, setActionModal)}
                </Authorized>
              </div>
            </div>
          }
          footer={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span className="small">
                Showing: {renderPaginationText(_formattedEmployees.length, currentPageNumber, ITEMS_PER_PAGE)}
              </span>
              <Pagination
                currentPage={currentPageNumber}
                totalPageCount={Math.ceil(
                  _formattedEmployees.length / ITEMS_PER_PAGE
                )}
                onChange={setCurrentPageNumber}
                terminalNeighbourCount={1}
                neighbourCount={1}
                jumpToLabel="Go to"
              />
            </div>
          }
          {...getTableProps()}
          data={formattedEmployees}
        />
      </Authorized>
      <Drawer
        open={isAddEmployeeModalActive}
        onClose={() => toggleAddEmployeeModal(false)}
        padding={0}
        stickPosition="right"
        withCloseButton
        size="800px"
        borderRadius="16px"
      >
        <AddEmployeeForm
          onEmployeeAdd={() => toggleAddEmployeeModal(false)}
          editValues={userInputToBeEdited}
          isSignedUp={isEmployeeSignedUp}
          employerMetadata={employerMetadata}
        />
      </Drawer>
      <Modal active={actionModal !== null} cancellable={false}>
        {actionModal && (
          <EmployeeActionModalContent
            action={actionModal.action}
            items={actionModal.data}
            onClose={() => setActionModal(null)}
            onFinish={() => {
              clearSelection();
              setActionModal(null);
              dispatch(storeDispatch, getEmployees(vendorId));
            }}
          />
        )}
      </Modal>
    </div>
  );
}
