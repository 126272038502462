import React, {useEffect, useMemo, useState} from "react";
import {
  dateToInputElemDate,
  downloadExcelFromArrayOfObjects,
  formatDate,
  getErrorMsgFromResponse,
  getParamFromUrl,
  getTodayDateForInputField,
  isDateOlderThan,
  isEmptyString,
  navigateTo
} from "../../../utils";
import dispatch from "../../../middleware";
import {useDispatch, useSelector} from "react-redux";
import DataTable from "react-data-table-component";
import {NotifierBgColor, RoutePaths, Transaction,} from "../../../models";
import {getAllEmployeesTransaction} from "../../../actions/employee";
import FullScreenLoader from "../../../components/fullscreen-loader";
import {showNotifier} from "../../../actions/ui";
import {TransactionStatusDto} from "../../../dto";
import {Select} from "../../../components/input-functions";
import getEffect from "../effect";
import Authorized from "../../../authorization/authorized";
import {AccessType, Resource} from "../../../authorization/authorization.enum";
import {ReduxState} from "../../../reducers";
import isAuthorized from "../../../authorization/authorizationAccess";

const statusOptions = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Transferred",
    value: TransactionStatusDto.TRANSFERRED,
  },
  {
    label: "Deducted From Salary",
    value: TransactionStatusDto.DEDUCTED_FROM_SALARY,
  },
  {
    label: "Repaid",
    value: TransactionStatusDto.REPAID,
  },
]
const tableStatusConfig: any = {
  [TransactionStatusDto.TRANSFERRED]: {
    className: "success-btn",
    label: "Transferred",
  },
  [TransactionStatusDto.DEDUCTED_FROM_SALARY]: {
    className: "success-btn",
    label: "Deducted From Salary"
  },
  [TransactionStatusDto.REPAID]: {
    className: "success-btn",
    label: "Repaid"
  }
}

const TransactionColumn = [
    {
      minWidth: "20%",
      left: true,
      name: "Name",
      selector: "name",
      cell: (row: any) => {
        const isClickable = Boolean(row.employeeId && row.employerEmployeeId);
        const handleClick = isClickable ? () => {
          const vendorId = getParamFromUrl("vendorId");
          const path = vendorId ? `/vendors/employeeProfile` : RoutePaths.EMPLOYEE_PROFILE
          let query = `?empId=${row.employeeId}&employerEmployeeId=${row.employerEmployeeId}`
          if (vendorId) {
            query = `${query}&vendorId=${vendorId}`
          }
          navigateTo(
              `${path}${query}`
          )
        } : undefined;

      return (
        <span
          className={`${isClickable ? "secondary-color cursor-pointer " : ""}fs-12 font-weight-bold`}
          onClick={handleClick}
        >
          {row.name}
        </span>
      )},
    },
    {
      minWidth: "20%",
      center: true,
      name: "Employee Id",
      selector: "employerEmployeeId",
      cell: (row: any) => <span className="primary-color fs-12">{row.employerEmployeeId}</span>,
    },
    {
      minWidth: "15%",
      center: true,
      name: "Date",
      selector: "date",
      cell: (row: any) => <span className="primary-color fs-12">{row.date}</span>,
    },
    {
      minWidth: "15%",
      center: true,
      name: "Time",
      selector: "time",
      cell: (row: any) => <span className="primary-color fs-12">{row.time}</span>,
    },
    {
      minWidth: "15%",
      name: "Amount Requested",
      center: true,
      selector: "amount",
      cell: (row: any) => (
        <span className="primary-color fs-12">{row.amount}</span>
      ),
    },
    {
      minWidth: "15%",
      center: true,
      name: "Status",
      selector: "status",
      cell: (row: any) => (
        <span className={tableStatusConfig[row.status]?.className || "primary-color fs-12"}>{tableStatusConfig[row.status]?.label || row.status}</span>
      ),
    },
  ];
  function formatTransactionData(transactions: Transaction[], transactionStatus: string) {
    const formattedData = transactions.map((transaction: Transaction) => {
      return {
        date: formatDate(new Date(transaction.createdAt)),
        time: new Date(transaction.createdAt).toLocaleTimeString(),
        amount: transaction.amount,
        status: transaction.status,
        name: transaction.employeeDataSnapshot?.fullName ?? transaction.userDataSnapshot?.name?.data,
        employerEmployeeId: transaction.employeeDataSnapshot?.employerEmployeeId,
        employeeId: transaction.employeeId
      };
    });
    const statusesToBeFilltered = statusOptions.map(el => el.value);

    return formattedData.filter((t) => {
      if (transactionStatus === "ALL") return statusesToBeFilltered.includes(t.status);
      return t.status === transactionStatus;
    });
  }
  function validateDate(startDate: Date, endDate: Date) {
    return !isDateOlderThan(1, startDate)
  }

function getInitialDateRange(
  startDateString: string | null,
  endDateString: string | null
) {
  let startDate: Date;
  let endDate: Date;

  if (startDateString && endDateString) {
    startDate = new Date(startDateString);
    endDate = new Date(endDateString);
  } else {
    startDate = new Date();
    endDate = new Date();

    startDate.setDate(1);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
  }

  return {
    startDate: dateToInputElemDate(startDate),
    endDate: dateToInputElemDate(endDate),
  };
}

export default function Transactions() {
  const storeDispatch = useDispatch();
  useEffect(getEffect("Transactions", useDispatch()), []);
  const vendorId = getParamFromUrl("vendorId");
  const authResource = vendorId ? Resource.EMPLOYER_PORTAL_VENDORS_TRANSACTIONS : Resource.EMPLOYER_PORTAL_TRANSACTIONS;
  const startDateString = getParamFromUrl('startDate');
  const endDateString = getParamFromUrl('endDate');
  const [dateRange, updateDateRange] = useState(() => getInitialDateRange(startDateString, endDateString));
  const [transactions, updateTransactions] = useState<Transaction[]>([])
  const [isLoading, updateLoading] = useState<boolean>(false)
  const [transactionStatus, updateTransactionStatus] = useState<string>("ALL")
  const [errorMsg, updateErrorMsg] = useState("")
  const formattedTransactions = useMemo(
    () => formatTransactionData(transactions, transactionStatus),
    [transactions, transactionStatus]
  );
  const resources = useSelector(
      (state: ReduxState) => state.login.userDetails?.resources || {}
  );

  function handleDateChange(fieldName: string, val: string) {
    updateDateRange((prevState) => {
      return {
        ...prevState,
        [fieldName]: val,
      };
    });
    updateErrorMsg("")
  }
  function handleDownloadClick() {
    if (formattedTransactions.length < 1) return;
    const transactionsArr = formattedTransactions.map(transaction => ({
      "Employee Id": transaction.employerEmployeeId || "NA",
      "Name": transaction.name || "NA",
      "Amount": transaction.amount,
      "Status": transaction.status,
      "Date": transaction.date,
      "Time": transaction.time,
    }))
    downloadExcelFromArrayOfObjects(transactionsArr, "transactions-report.xlsx");
  }
  function renderTableHeader() {
    return (
        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
          <p className="table-title fw-600 fs-18 m-0">Transactions</p>
          <div className="d-flex align-items-center">
            <Select
                value={transactionStatus}
                options={statusOptions}
                fieldName={"TRANSACTION_STATUS"}
                onChange={(f: string, val: string | number) => updateTransactionStatus(String(val))}
            />
            <button 
              className="btn outline-btn w-unset fs-12 ml-2 b-color-secondary secondary-color"
              onClick={handleDownloadClick}
              disabled={formattedTransactions.length < 1}
            >
              Download
            </button>
          </div>
        </div>
    )
  }
  async function handleSubmit(e?: React.FormEvent<HTMLFormElement>) {
    if (e) {
      e.preventDefault();
    }
    updateLoading(true)
    try {
      const transactionStartDate = new Date(dateRange.startDate);
      const transactionEndDate = new Date(dateRange.endDate);
      if (
          validateDate(transactionStartDate, transactionEndDate)
      ) {
        transactionStartDate.setHours(0, 0, 0);
        transactionEndDate.setHours(23, 59, 59, 999);

        const response = await dispatch(
            storeDispatch,
            getAllEmployeesTransaction(transactionStartDate, transactionEndDate, vendorId)
        );
        if (response.transactions) {
          updateTransactions(response.transactions)
          updateTransactionStatus("ALL")
        }
        updateErrorMsg("")
      } else {
        updateErrorMsg("Invalid date range! Please select a date range within 1 year.")
      }

    } catch (e) {
      await dispatch(
          storeDispatch,
          showNotifier(NotifierBgColor.ERROR, getErrorMsgFromResponse(e))
      );
    }
    updateLoading(false)
  }

  function renderNotAccessible() {
    return (
        <div className={"d-flex justify-content-center align-items-center"}>
          You aren't authorized to access this page
        </div>
    )
  }

  useEffect(() => {
    if(
      isAuthorized(resources, authResource, AccessType.READ)
    ) {
      handleSubmit();
    }
  }, []);
  return (
    <div>
      <FullScreenLoader active={isLoading}/>
      <Authorized
        resourceName={authResource}
        requiredAccessType={AccessType.READ}
        unAuthorizedView={renderNotAccessible()}
      >
        <>
          <p className="fw-600 fs-20 primary-color">Choose Date</p>
          <form className="row" onSubmit={handleSubmit}>
          <div className="col-md-5">
            <div className="form-signin-label-group">
              <input
                  type="date"
                  id="fromDate"
                  className="form-control input-login"
                  placeholder="From Date"
                  value={dateRange.startDate}
                  onChange={(e) => handleDateChange("startDate", e.target.value)}
                  max={getTodayDateForInputField()}
                  required
              />
              <label htmlFor="fromDate">From Date</label>
            </div>
          </div>
          <div className="col-md-5">
            <div className="form-signin-label-group">
              <input
                  type="date"
                  id="toDate"
                  className="form-control input-login"
                  placeholder="To Date"
                  value={dateRange.endDate}
                  onChange={(e) => handleDateChange("endDate", e.target.value)}
                  min={dateRange.startDate}
                  max={getTodayDateForInputField()}
                  required
              />
              <label htmlFor="toDate">To Date</label>
            </div>
          </div>

          <div className="col-md-2">
            <button
                type="submit"
                className=" btn primary-button"
                disabled={
                  isEmptyString(dateRange.startDate) || isEmptyString(dateRange.endDate)
                }
            >
              Search
            </button>
          </div>
          <div className="text-small text-danger col-sm-12">
            {errorMsg}
          </div>
          <div className="col-sm-12 mt-3">
            <DataTable
                title={renderTableHeader()}
                pagination={true}
                columns={TransactionColumn}
                data={formattedTransactions}
                className="table-wrapper"
                highlightOnHover
            />
          </div>
        </form>
        </>
      </Authorized>
    </div>
  );
}
