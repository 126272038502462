import { useCallback, useMemo, useState } from "react";
import { TableColumn } from "..";
import { useDebounce } from "../../../../hooks/use-debounce";
import { FilterState } from "../../filter";
import { SortOrder } from "../sort-button";

type SortState<T> = { by: keyof T; order: SortOrder };
type TableColumnVisibility<T> = Partial<
  Record<keyof T, { visible: boolean; disabled?: boolean }>
>;

interface useTableStateProps<T> {
  initialState?: {
    selectedRowIds?: Array<keyof T>;
    currentPageNumber?: number;
    sortState?: SortState<T>;
    searchText?: string;
    tableColumnVisibility?: TableColumnVisibility<T>;
    tableFilters?: FilterState[];
  };
  data: T[];
  columns: TableColumn<T>[];
  config: {
    hasRowSelection?: boolean;
    searchDebounceTime?: number;
    hasSort?: boolean;
    uniqueKey: keyof T;
  };
}

export function useTableState<T extends Record<string, any>>({
  initialState,
  data,
  columns,
  config: {
    hasRowSelection = false,
    searchDebounceTime = 500,
    hasSort = false,
    uniqueKey,
  },
}: useTableStateProps<T>) {
  const [selectedRowIds, setSelectedRowIds] = useState<Array<keyof T>>(
    initialState?.selectedRowIds ?? []
  );
  const selectedRows = useMemo(
    () =>
      data.filter((eachData) => selectedRowIds.includes(eachData[uniqueKey])),
    [data, selectedRowIds]
  );
  const clearSelection = () => setSelectedRowIds([]);

  const [currentPageNumber, _setCurrentPageNumber] = useState<number>(
    initialState?.currentPageNumber ?? 1
  );
  const setCurrentPageNumber: typeof _setCurrentPageNumber = (value) => {
    _setCurrentPageNumber(value);
    clearSelection();
  }

  const resetPageAndSelection = () => {
    setCurrentPageNumber(initialState?.currentPageNumber ?? 1);
    setSelectedRowIds(initialState?.selectedRowIds ?? []);
  };

  const [sortState, setSortState] = useState<SortState<T> | undefined>(
    initialState?.sortState
  );
  const [tableFilters, _setTableFilters] = useState<FilterState[]>(
    initialState?.tableFilters ?? []
  );
  const setTableFilters: typeof _setTableFilters = (value) => {
    _setTableFilters(value);
    resetPageAndSelection();
  };

  const [searchText, setSearchText] = useState<string>(
    initialState?.searchText ?? ""
  );
  const debouncedSearchText = useDebounce(
    searchText,
    searchDebounceTime,
    resetPageAndSelection
  );

  const [_tableColumnVisibility, _setTableColumnVisibility] = useState<
    TableColumnVisibility<T>
  >(initialState?.tableColumnVisibility ?? {});
  const setTableColumnVisibility = useCallback(
    (key: keyof T, visible: boolean) => {
      _setTableColumnVisibility((prev) => {
        const clone = { ...prev };
        clone[key] = { ...clone[key], visible };
        return clone;
      });
    },
    []
  );

  const _columns = useMemo(
    () =>
      columns.map((column) => {
        const visibility = _tableColumnVisibility[column.key];
        if (visibility?.disabled === true) return column;
        return { ...column, hidden: visibility?.visible === false };
      }),
    [columns, _tableColumnVisibility]
  );

  const toggleableColumns = useMemo(
    () =>
      columns.map(({ key, label }) => ({
        key,
        label,
        visible: _tableColumnVisibility[key]?.visible !== false,
        disabled: !!_tableColumnVisibility[key]?.disabled,
      })),
    [columns, _tableColumnVisibility]
  );

  const toggleTableColumnVisibility = useCallback((e) => {
    const { value, checked } = e.currentTarget;
    setTableColumnVisibility(value, checked);
  }, []);

  const toggleAllTableColumnVisibility = useCallback((action: "SHOW" | "HIDE") => {
    const visible = action === "SHOW";
    
    _setTableColumnVisibility(prev => {

      const _record = columns.reduce((record, { key }) => {
        if (prev[key]?.disabled) return record;
        record[key] = { visible: visible, disabled: prev[key]?.disabled };
        return record;
      }, {} as TableColumnVisibility<T>);

      return { ...prev, ..._record };
    });
  }, [columns])

  const getTableProps = useCallback(() => {
    return {
      uniqueKey,
      columns: _columns,
      ...(hasRowSelection
        ? {
            hasRowSelectCheckbox: true,
            selectedItems: selectedRowIds,
            onSelectChange: setSelectedRowIds,
          }
        : {}),
      ...(hasSort ? { sortState, onSortChange: setSortState } : {}),
    };
  }, [_columns, sortState, selectedRowIds]);

  return {
    currentPageNumber,
    setCurrentPageNumber,
    searchText,
    setSearchText,
    debouncedSearchText,
    toggleableColumns,
    toggleTableColumnVisibility,
    toggleAllTableColumnVisibility,
    tableFilters,
    setTableFilters,
    sortState,
    selectedRows,
    clearSelection,
    getTableProps,
  };
}
