import {Actions} from "../actions/employee";
import {EmployeeDetails, TransactionPendingApproval, WorkerPendingApproval} from "../models";
import {EmployerAdminDto, StatsDto} from "../dto";
import {VendorAction} from "../actions/vendor";

export interface EmployeeState {
    loading: boolean;
    employees: EmployeeDetails[],
    vendorEmployees: EmployeeDetails[],
    stats: StatsDto | null,
    employerAdmins: EmployerAdminDto[],
    vendorAdmins: EmployerAdminDto[],
    workerPendingApprovals: WorkerPendingApproval[],
    transactionPendingApprovals: TransactionPendingApproval[],
}

const defaultState: EmployeeState = {
    loading: false,
    employees: [],
    stats: null,
    employerAdmins: [],
    vendorEmployees: [],
    vendorAdmins: [],
    workerPendingApprovals: [],
    transactionPendingApprovals: [],
};

export default function EmployeeStateReducer(state = defaultState, action: any) : EmployeeState {
    switch (action.type) {
        case VendorAction.REQUEST_VENDOR_STATS:
            return {
                ...state,
                loading: true,
                stats: null,
            };
        case Actions.REQUEST_EMPLOYER_ADMIN_LIST:
            return {
                ...state,
                loading: true,
            };
        case `${Actions.REQUEST_EMPLOYER_ADMIN_LIST}_SUCCESS`:
            return {
                ...state,
                loading: false,
                employerAdmins: action.payload,
            };
        case `${Actions.REQUEST_EMPLOYER_ADMIN_LIST}_FAILURE`:
            return {
                ...state,
                loading: false,
                employerAdmins: [],
            };
        case Actions.REQUEST_GET_EMPLOYEES:
            return {
                ...state,
                loading: true,
            };
        case `${Actions.REQUEST_GET_EMPLOYEES}_SUCCESS`:
            return {
                ...state,
                loading: false,
                employees: action.payload,
            };
        case `${Actions.REQUEST_GET_EMPLOYEES}_FAILURE`:
            return {
                ...state,
                loading: false,
                employees: [],
            };
        case VendorAction.REQUEST_GET_VENDOR_EMPLOYEES:
            return {
                ...state,
                loading: true,
            };
        case `${VendorAction.REQUEST_GET_VENDOR_EMPLOYEES}_SUCCESS`:
            return {
                ...state,
                loading: false,
                vendorEmployees: action.payload,
            };
        case `${VendorAction.REQUEST_GET_VENDOR_EMPLOYEES}_FAILURE`:
            return {
                ...state,
                loading: false,
                vendorEmployees: [],
            };
        case VendorAction.REQUEST_GET_VENDOR_ADMINS:
            return {
                ...state,
                loading: true,
            };
        case `${VendorAction.REQUEST_GET_VENDOR_ADMINS}_SUCCESS`:
            return {
                ...state,
                loading: false,
                vendorAdmins: action.payload,
            };
        case `${VendorAction.REQUEST_GET_VENDOR_ADMINS}_FAILURE`:
            return {
                ...state,
                loading: false,
                vendorAdmins: [],
            };
        case Actions.REQUEST_ADD_EMPLOYEE:
            return {
                ...state,
                loading: true,
            };
        case `${Actions.REQUEST_ADD_EMPLOYEE}_SUCCESS`:
            return {
                ...state,
                loading: false,
            };
        case `${Actions.REQUEST_ADD_EMPLOYEE}_FAILURE`:
            return {
                ...state,
                loading: false,
            };
        case Actions.REQUEST_UPDATE_EMPLOYEE:
            return {
                ...state,
                loading: true,
            };
        case `${Actions.REQUEST_UPDATE_EMPLOYEE}_SUCCESS`:
            return {
                ...state,
                loading: false,
            };
        case `${Actions.REQUEST_UPDATE_EMPLOYEE}_FAILURE`:
            return {
                ...state,
                loading: false,
            };
        case Actions.REQUEST_ADD_EMPLOYEE_IN_BULK:
            return {
                ...state,
                loading: true,
            };
        case `${Actions.REQUEST_ADD_EMPLOYEE_IN_BULK}_SUCCESS`:
            return {
                ...state,
                loading: false,
            };
        case `${Actions.REQUEST_ADD_EMPLOYEE_IN_BULK}_FAILURE`:
            return {
                ...state,
                loading: false,
            };
        case Actions.REQUEST_GET_EMPLOYEE_BULK_UPLOAD_HISTORY:
        case Actions.REQUEST_GET_EMPLOYEE_BULK_UPLOAD_HISTORY:
            return {
                ...state,
                loading: true,
            };
        case `${Actions.REQUEST_GET_EMPLOYEE_BULK_UPLOAD_HISTORY}_SUCCESS`:
        case `${Actions.REQUEST_GET_EMPLOYEE_BULK_UPLOAD_HISTORY}_FAILURE`:
        case `${Actions.REQUEST_GET_EMPLOYEE_BULK_UPLOAD_HISTORY}_SUCCESS`:
        case `${Actions.REQUEST_GET_EMPLOYEE_BULK_UPLOAD_HISTORY}_FAILURE`:
            return {
                ...state,
                loading: false,
            };
        case Actions.REQUEST_REMOVE_EMPLOYEE:
            return {
                ...state,
                loading: true,
            };
        case `${Actions.REQUEST_REMOVE_EMPLOYEE}_SUCCESS`:
            return {
                ...state,
                loading: false,
            };
        case `${Actions.REQUEST_REMOVE_EMPLOYEE}_FAILURE`:
            return {
                ...state,
                loading: false,
            };
        case Actions.REQUEST_REMOVE_EMPLOYEE_IN_BULK:
            return {
                ...state,
                loading: true,
            };
        case `${Actions.REQUEST_REMOVE_EMPLOYEE_IN_BULK}_SUCCESS`:
            return {
                ...state,
                loading: false,
            };
        case `${Actions.REQUEST_REMOVE_EMPLOYEE_IN_BULK}_FAILURE`:
            return {
                ...state,
                loading: false,
            };
        case VendorAction.CLEAR_VENDOR_DATA:
            return {
                ...state,
                vendorEmployees: [],
                vendorAdmins: [],
            }
        case Actions.REQUEST_EMPLOYER_ADMIN_PENDING_WORKER_APPROVALS:
        case Actions.REQUEST_EMPLOYER_ADMIN_PENDING_TRANSACTION_APPROVALS:
        case Actions.REQUEST_EDIT_EMPLOYEE_PENDING_APPROVAL:
            return {
                ...state,
                loading: true,
            }
        case `${Actions.REQUEST_EMPLOYER_ADMIN_PENDING_WORKER_APPROVALS}_SUCCESS`:
            return {
                ...state,
                loading: false,
                workerPendingApprovals: action.payload,
            }
        case `${Actions.REQUEST_EMPLOYER_ADMIN_PENDING_WORKER_APPROVALS}_FAILURE`:
            return {
                ...state,
                loading: false,
                workerPendingApprovals: [],
            }
        case `${Actions.REQUEST_EMPLOYER_ADMIN_PENDING_TRANSACTION_APPROVALS}_SUCCESS`:
            return {
                ...state,
                loading: false,
                transactionPendingApprovals: action.payload,
            }
        case `${Actions.REQUEST_EMPLOYER_ADMIN_PENDING_TRANSACTION_APPROVALS}_FAILURE`:
            return {
                ...state,
                loading: false,
                transactionPendingApprovals: [],
            }
        case `${Actions.REQUEST_EDIT_EMPLOYEE_PENDING_APPROVAL}_SUCCESS`:
        case `${Actions.REQUEST_EDIT_EMPLOYEE_PENDING_APPROVAL}_FAILURE`:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}