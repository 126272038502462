import React, {useEffect} from 'react';
import 'react-circular-progressbar/dist/styles.css';
import "./styles.css";
import { useSelector} from "react-redux";
import {ReduxState} from "../reducers";
import {
    HashRouter as Router,
    Route, Switch
} from "react-router-dom";
import {Dashboard} from "../containers/dashboard";
import FirebaseAction from '../containers/firebase-action';
import Login from "../containers/login";
import ForgotPassword from "../containers/login/forgotPassword";
import ResendEmail from '../containers/login/resendEmail';
import { idTokenName } from '../middleware';

function getRoutes(loginStatus: boolean) {
    return (
        <Router>
            <Switch>
                <Route exact path="/forgotPassword" component={ForgotPassword}/>
                <Route exact path="/resendEmail" component={ResendEmail}/>
                <Route exact path="/firebaseAction" component={FirebaseAction}/>
                <Route path="/" component={loginStatus ? Dashboard : Login}/>
            </Switch>
        </Router>
    );
}

function listenLogoutFromOtherTabs() {
    window.addEventListener('storage', e => {
        if(e.key === idTokenName && e.oldValue && !e.newValue) {
            window.location.replace(window.location.origin)
        }
    });
}
function App() {
    const isLoggedIn: boolean = useSelector((state: ReduxState) => state.login.isLoggedIn);
    useEffect(() => {
        listenLogoutFromOtherTabs();
    }, [])
    return getRoutes(isLoggedIn);
}
export default App;
