import { AttendanceDetails } from "../models";
import { Actions } from "../actions/attendance";
import { AttendanceCycleDto } from "../dto";
import {VendorAction} from "../actions/vendor";


export interface AttendanceState {
    loading: boolean;
    attendances: AttendanceDetails[],
    vendorAttendances: AttendanceDetails[],
    attendanceCycle: AttendanceCycleDto | null,
    vendorAttendanceCycle: AttendanceCycleDto | null
}

const defaultState: AttendanceState = {
    loading: false,
    attendances: [],
    vendorAttendances: [],
    attendanceCycle: null,
    vendorAttendanceCycle: null,
};

export default function AttendanceReducer(state = defaultState, action: any): AttendanceState {
    switch (action.type) {
        case Actions.REQUEST_ATTENDANCE_LIST:
            return {
                ...state,
                loading: true
            };
        case `${Actions.REQUEST_ATTENDANCE_LIST}_SUCCESS`:
            return {
                ...state,
                loading: false,
                attendances: action.payload.attendances
            };
        case `${Actions.REQUEST_ATTENDANCE_LIST}_FAILURE`:
            return {
                ...state,
                loading: false,
            };
        case Actions.REQUEST_DOWNLOAD_ATTENDANCE:
            return {
                ...state,
                loading: true,
            };
        case `${Actions.REQUEST_DOWNLOAD_ATTENDANCE}_FAILURE`:
            return {
                ...state,
                loading: false,
            };
        case `${Actions.REQUEST_DOWNLOAD_ATTENDANCE}_SUCCESS`:
            return {
                ...state,
                loading: false,
            };
        case Actions.REQUEST_UPLOAD_ATTENDANCE_BULK:
            return {
                ...state,
                loading: true,
            };
        case `${Actions.REQUEST_UPLOAD_ATTENDANCE_BULK}_FAILURE`:
            return {
                ...state,
                loading: false,
            };
        case `${Actions.REQUEST_UPLOAD_ATTENDANCE_BULK}_SUCCESS`:
            return {
                ...state,
                loading: false,
            };
        case Actions.REQUEST_ATTENDANCE_CYCLE:
            return {
                ...state,
                loading: true,
            };
        case `${Actions.REQUEST_ATTENDANCE_CYCLE}_FAILURE`:
            return {
                ...state,
                loading: false,
            };
        case `${Actions.REQUEST_ATTENDANCE_CYCLE}_SUCCESS`:
            return {
                ...state,
                loading: false,
                attendanceCycle: action.payload
            };
        case VendorAction.REQUEST_VENDOR_ATTENDANCE_CYCLE:
            return {
                ...state,
                loading: true,
            };
        case `${VendorAction.REQUEST_VENDOR_ATTENDANCE_CYCLE}_FAILURE`:
            return {
                ...state,
                loading: false,
            };
        case `${VendorAction.REQUEST_VENDOR_ATTENDANCE_CYCLE}_SUCCESS`:
            return {
                ...state,
                loading: false,
                vendorAttendanceCycle: action.payload
            };
        case VendorAction.REQUEST_VENDOR_ATTENDANCE_LIST:
            return {
                ...state,
                loading: true
            };
        case `${VendorAction.REQUEST_VENDOR_ATTENDANCE_LIST}_SUCCESS`:
            return {
                ...state,
                loading: false,
                vendorAttendances: action.payload.attendances,
            };
        case `${VendorAction.REQUEST_VENDOR_ATTENDANCE_LIST}_FAILURE`:
            return {
                ...state,
                loading: false,
                vendorAttendances: [],
            };
        case VendorAction.CLEAR_VENDOR_DATA:
            return {
                ...state,
                vendorAttendances: [],
                vendorAttendanceCycle: null,
            }
        default:
            return state
    }
}
