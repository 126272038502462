import React, { useState } from "react";
import { MAX_QTY_OF_RECENT_ACTIVITY } from "../../../constants";
import {AdminActivity} from "../../../dto";

interface ActivityProps {
    activities: AdminActivity | null;
    onShowMoreClick?: () => void;
}

type EmployerAdmins = {
    [x: string]: {
        name: string;
        email: string;
    }
}

const ActivityBoxStyles: any = {
    height: 323,
    width: "100%",
    overflowX: "hidden",
    overflowY: "scroll",
    borderRadius: 8,
    border: "20px solid white",
    borderBottom: "30px solid white",
}

const actionTypeToText: any = {
    ATTENDANCE_UPLOAD: {
        label: "Attendance uploaded",
    },
    ADD_EMPLOYEES_BULK_UPLOAD: {
        label: "Bulk added employees"
    },
    PAYROLL_UPLOAD: {
        label: "Payroll uploaded"
    },
    TERMINATED_EMPLOYEES_UPLOAD: {
        label: "Bulk Terminate employees"
    }
}

function formatActivityDate(updatedAt: string) {
    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date(updatedAt)
    return `${date.getDate()} ${month[date.getMonth()]} ${date.getFullYear()}`
}

function renderField(value?: string) {
  if (!value) return;
  return <p className={"light-gray fw-300 fs-12 m-0"}>{value}</p>;
}

function renderActivityList(activitiyObj: AdminActivity | null) {
    const activities = activitiyObj?.activities;
    const employerAdmins = activitiyObj?.employerAdminsDetails.reduce<EmployerAdmins>(
      (accum, admin) => {
        accum[admin._id] = { name: admin.name, email: admin.email };
        return accum;
      },
      {}
    );
    if (!activities) return <div className="text-center p-4 text-secondary">Loading your Activity...</div>
    if (activities.length === 0 || !employerAdmins) return <div className="text-center p-4 text-secondary">No Activity available</div>
    return activities.map((val, index) => (
      <div
        className="d-block my-3"
        key={index}
        style={{ fontSize: 14, overflow: "hidden" }}
      >
        <span className={"table-title float-left fw-600 fs-12"}>
          {actionTypeToText[val.actionType].label} <br />
          {renderField(employerAdmins[val.employerAdminId]?.name)}
          {renderField(employerAdmins[val.employerAdminId]?.email)}
        </span>
        <p className={"float-right light-gray fw-300 fs-12 m-0"}>
          {formatActivityDate(val.updatedAt)}
        </p>
      </div>
    ));
}

export default function Activity(props: ActivityProps) {
    const [isRecentActivity, setIsRecentActivity] = useState(true);
    function handleClick() {
        setIsRecentActivity(false);
        if (typeof props.onShowMoreClick === "function") {
            props.onShowMoreClick();
        }
    }
    function renderShowMore(data: AdminActivity | null, isActive: boolean) {
        if (!data || !isActive) return;
        if (data.activities.length < MAX_QTY_OF_RECENT_ACTIVITY) return;
        return <button className="btn btn-link btn-sm" onClick={handleClick}>Show More</button>
    }
    return (
        <div className={"bg-white p-1 mt-3 mt-md-0"} style={ActivityBoxStyles}>
            <p className={"table-title fw-600 fs-18"}>Recent Activities</p>
            {renderActivityList(props.activities)}
            {renderShowMore(props.activities, isRecentActivity)}
        </div>
    )
}