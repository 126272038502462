import React, {FunctionComponent} from 'react';
import {ReactComponent as EmployeeImg} from "../../images/employee.svg";
import {ReactComponent as Attendance} from "../../images/attendance.svg";
import {ReactComponent as Dashboard} from "../../images/dashboard.svg";
import {ReactComponent as Payroll} from "../../images/payroll.svg";
import {ReactComponent as TransactionSvg} from "../../images/transaction.svg";
import {ReactComponent as SettingsIcon} from "../../images/settings.svg";
import {ReactComponent as VendorIcon} from "../../images/vendor-black.svg";
import {ReactComponent as UserVerificationIcon} from "../../images/user-verification.svg";
import {Link} from "react-router-dom";
import {EmployerDetails, RoutePaths} from '../../models';
import {useSelector} from 'react-redux';
import {ReduxState} from '../../reducers';
import Logo from "./../../images/refyne-logo.svg";
import Authorized from "../../authorization/authorized";
import {AccessType, Resource} from "../../authorization/authorization.enum";
import isAuthorized from "../../authorization/authorizationAccess";
import AnalyticsEvent from '../../analytics/events';
import { logEventInFirebase } from '../../analytics/firebase.analytics';

export interface MenuButtonProps {
    imageComponent: FunctionComponent ; // Image component
    buttonText: string;
    auxClasses?: string;
    onClick?: () => void;
    selected: boolean;
}

export function MenuItem(props: MenuButtonProps) {
    return <div className={`menu-item ${props.auxClasses ? props.auxClasses : ""} ${props.selected? 'menu-item-selected': ''}`}
                onClick={props.onClick}>
        <div className="d-flex justify-content-center justify-content-md-start align-items-center">
            {((ImgComponent: FunctionComponent) => <ImgComponent/>)(props.imageComponent)}
            <span className="d-none d-md-block">{props.buttonText}</span>
        </div>
    </div>
}
function renderEmployerName(details: EmployerDetails) {

    if (!details) return null;
    if (details.name) 
    return (<span className="primary-color fw-500">
        {details.name.slice(0, 13).toUpperCase()}
            {
                details.name.length > 13 ? "..." : ""
            }
    </span>
    )
    return null;
}
function hasMinimumOneVendorAccess(
    resources: {
        [name: string]: string[]
    }
) {
    return (
        isAuthorized(resources, Resource.EMPLOYER_PORTAL_VENDORS_ADMIN, AccessType.READ) ||
        isAuthorized(resources, Resource.EMPLOYER_PORTAL_VENDORS_EMPLOYEES, AccessType.READ) ||
        isAuthorized(resources, Resource.EMPLOYER_PORTAL_VENDORS_PAYROLL, AccessType.READ) ||
        isAuthorized(resources, Resource.EMPLOYER_PORTAL_VENDORS_TRANSACTIONS, AccessType.READ) ||
        isAuthorized(resources, Resource.EMPLOYER_PORTAL_VENDORS_ATTENDANCE, AccessType.READ)
    )
}
function hasMinimumOneEmployerAccess(
    resources: {
        [name: string]: string[]
    }
) {
    return (
        isAuthorized(resources, Resource.EMPLOYER_PORTAL_ADMIN, AccessType.READ) ||
        isAuthorized(resources, Resource.EMPLOYER_PORTAL_EMPLOYEES, AccessType.READ) ||
        isAuthorized(resources, Resource.EMPLOYER_PORTAL_ATTENDANCE, AccessType.READ) ||
        isAuthorized(resources, Resource.EMPLOYER_PORTAL_PAYROLL, AccessType.READ) ||
        isAuthorized(resources, Resource.EMPLOYER_PORTAL_TRANSACTIONS, AccessType.READ)
    )
}
function handleAnalyticsEvent(event: AnalyticsEvent, params?: Record<string, any>) {
    return () => logEventInFirebase(event, params);
}
export function Menu() {
    const resources = useSelector((state: ReduxState) => state.login?.userDetails?.resources || {})
    const employerDetails: EmployerDetails = useSelector((state: ReduxState) => state.employer.employerDetails)
    const isEmployeeApprovalEnabled = useSelector((state: ReduxState) => !!state.employer.employerConfig?.isEmployeeApprovalEnabled);
    const isSalaryTopupViewEnabled = useSelector((state: ReduxState) => !!state.employer.employerConfig?.isSalaryTopupViewEnabled);

    let marginClasses = ""
    const path = window.location.href // TODO: more elegant way to do it ?
    return <div className="menu">
        <div className="nav-top">
            <div className="d-flex align-items-center mb-4">
                <span className="name-circle-toggler-sidenav mr-2">
                    {employerDetails && employerDetails.name && employerDetails.name.charAt(0).toUpperCase()}
                </span>
                <span className="d-none d-md-block">{renderEmployerName(employerDetails)}</span>
            </div>
            <div className={"pt-4"}>
                {hasMinimumOneEmployerAccess(resources) && <Link 
                    to={RoutePaths.DASHBOARD} 
                    className="text-decoration-none" 
                    onClick={handleAnalyticsEvent(AnalyticsEvent.DASHBOARD_SECTION_CLICKED)}
                >
                    <MenuItem
                        buttonText="Dashboard"
                        auxClasses={marginClasses}
                        selected={path.endsWith(RoutePaths.DASHBOARD)}
                        imageComponent={Dashboard}
                    />
                </Link>}
                <Authorized
                    resourceName={Resource.EMPLOYER_PORTAL_EMPLOYEES}
                    requiredAccessType={AccessType.READ}
                >
                    <Link 
                        to={RoutePaths.EMPLOYEES} 
                        className="text-decoration-none" 
                        onClick={handleAnalyticsEvent(AnalyticsEvent.EMPLOYEES_SECTION_CLICKED)}
                    >
                        <MenuItem
                            buttonText="Employees"
                            auxClasses={marginClasses}
                            selected={path.endsWith(RoutePaths.EMPLOYEES)} // TODO: remove this hack.
                            imageComponent={EmployeeImg}
                        />
                    </Link>
                </Authorized>
                <Authorized
                    resourceName={Resource.EMPLOYER_PORTAL_ATTENDANCE}
                    requiredAccessType={AccessType.READ}
                >
                    <Link 
                        to={RoutePaths.ATTENDANCE} 
                        className="text-decoration-none" 
                        onClick={handleAnalyticsEvent(AnalyticsEvent.ATTENDANCE_SECTION_CLICKED)}
                    >
                        <MenuItem
                            buttonText="Attendance"
                            auxClasses={marginClasses}
                            selected={path.endsWith(RoutePaths.ATTENDANCE)}
                            imageComponent={Attendance}
                        />
                    </Link>
                </Authorized>
                <Authorized
                    resourceName={Resource.EMPLOYER_PORTAL_PAYROLL}
                    requiredAccessType={AccessType.READ}
                >
                    <Link 
                        to={RoutePaths.PAYROLL} 
                        className="text-decoration-none" 
                        onClick={handleAnalyticsEvent(AnalyticsEvent.PAYROLL_SECTION_CLICKED)}
                    >
                        <MenuItem
                            buttonText="Payroll"
                            auxClasses={marginClasses}
                            selected={path.endsWith(RoutePaths.PAYROLL)}
                            imageComponent={Payroll}
                        />
                    </Link>
                </Authorized>
                {isEmployeeApprovalEnabled && (
                    <Link
                        to={RoutePaths.USER_VERIFICATION}
                        className="text-decoration-none"
                    >
                        <MenuItem
                            buttonText="User Verification"
                            auxClasses={marginClasses}
                            selected={path.endsWith(RoutePaths.USER_VERIFICATION)}
                            imageComponent={UserVerificationIcon}
                        />
                    </Link>
                    )}
                <Authorized
                    resourceName={Resource.EMPLOYER_PORTAL_TRANSACTIONS}
                    requiredAccessType={AccessType.READ}
                >
                    <Link
                        to={RoutePaths.EMPLOYERS_TRANSACTIONS}
                        className="text-decoration-none"
                        onClick={handleAnalyticsEvent(AnalyticsEvent.TRANSACTIONS_SECTION_CLICKED)}
                    >
                        <MenuItem
                            buttonText="Transactions"
                            auxClasses={marginClasses}
                            selected={path.endsWith(RoutePaths.EMPLOYERS_TRANSACTIONS)}
                            imageComponent={TransactionSvg}
                        />
                    </Link>
                </Authorized>
                {isSalaryTopupViewEnabled && <Link
                    to={RoutePaths.SALARY_TOPUPS}
                    className="text-decoration-none"
                >
                    <MenuItem
                        buttonText="Salary Topups"
                        auxClasses={marginClasses}
                        selected={path.endsWith(RoutePaths.SALARY_TOPUPS)}
                        imageComponent={TransactionSvg}
                    />
                </Link>}
                {hasMinimumOneVendorAccess(resources) && <Link
                    to={RoutePaths.VENDORS}
                    className="text-decoration-none"
                    onClick={handleAnalyticsEvent(AnalyticsEvent.VENDORS_SECTION_CLICKED)}
                >
                    <MenuItem
                        buttonText="Vendors"
                        auxClasses={marginClasses}
                        selected={path.includes(RoutePaths.VENDORS) || path.includes("vendorId")}
                        imageComponent={VendorIcon}
                    />
                </Link>}
                <Authorized
                    resourceName={Resource.EMPLOYER_PORTAL_ADMIN}
                    requiredAccessType={AccessType.READ}
                >
                    <Link
                        to={RoutePaths.SETTINGS}
                        className="text-decoration-none"
                        onClick={handleAnalyticsEvent(AnalyticsEvent.SETTINGS_SECTION_CLICKED)}
                    >
                        <MenuItem
                            buttonText="Settings"
                            auxClasses={marginClasses}
                            selected={path.endsWith(RoutePaths.SETTINGS)}
                            imageComponent={SettingsIcon}
                        />
                    </Link>
                </Authorized>
            </div>
        </div>

        <img src={Logo} alt="Refyne Logo" className="nav-logo d-none d-md-block"/>
    </div>
}