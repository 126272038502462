import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import {config} from "../firebase/firebaseUtils";
import dispatch, {clearToken, MiddlewareAction, saveToken} from "../middleware";
import {getUser} from "./userInfo";
import {webUrl} from "../config";
import { LoggedInUserDetails } from "../models";
import { setFirebaseUserId, setFirebaseUserProperty } from "../analytics/firebase.analytics";

export enum Actions {
    FIREBASE_LOGIN = "FIREBASE_LOGIN",
    FIREBASE_REGISTER = "FIREBASE_REGISTER",
    CLEARE_FIREBASE_RESPONSE = "CLEARE_FIREBASE_RESPONSE",
    FIREBASE_VERIFICATION_MAIL = "FIREBASE_VERIFICATION_MAIL",
    STOP_LOADING = "STOP_LOADING",
    REQUEST_ALL_ROLES = "REQUEST_ALL_ROLES",
}
firebase.initializeApp(config, "REFYNE");

export function changePassword(email: string, currentPassword: string, newPassword: string): Promise<string> {
    return new Promise((resolve, reject) => {
        firebase
            .auth()
            .signInWithEmailAndPassword(email, currentPassword)
            .then((user) => {
                firebase
                    .auth()
                    .currentUser
                    ?.updatePassword(newPassword).then(() => {
                    resolve("Password has been updated")
                }).catch(function(err){
                    reject("Couldn't update password")
                });
            }).catch(function(err){
            reject("Current password is wrong!")
        });
    })
}

export async function login(email: string, password: string, storeDispatch: any) {
    dispatch(storeDispatch, firebaseLogin());
    try {
        const response = await firebase.auth().signInWithEmailAndPassword(email, password);
        if (!response.user?.emailVerified) {
            dispatch(storeDispatch, firebaseLogin("FAILURE", {message: "You haven't verified your email.", code: ""}));
            return;
        }
        // TODO: Deprecate it later
        const authToken = await response.user.getIdToken();
        saveToken(authToken);
        (window as any).getToken = () => {
            return authToken;
        };

        const user: LoggedInUserDetails = await dispatch(storeDispatch, getUser());
        setFirebaseUserId(user._id);
        setFirebaseUserProperty({ employerId: user.employerId });
        await dispatch(storeDispatch, firebaseLogin("SUCCESS"));
    } catch (error) {
        dispatch(storeDispatch, firebaseLogin("FAILURE", error));
    }
}

export function firebaseLogin(type?: string, response?: object): MiddlewareAction {
    return {actionName: `${Actions.FIREBASE_LOGIN}${type ? `_${type}` : ""}`, body: {response}}
}
export function firebaseRegister(type?: string, response?: object):MiddlewareAction {
    return {actionName: `${Actions.FIREBASE_REGISTER}${type ? `_${type}` : ""}`, body: {response}}
}
export function register(email: string, password: string, storeDispatch: any) {
    dispatch(storeDispatch, firebaseRegister());
    firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((response: any) => {
        response.user.getIdToken().then((token: string) => {
            saveToken(token);
            dispatch(storeDispatch, firebaseRegister("SUCCESS", response));
            sendVerificationMail(firebase.auth().currentUser, storeDispatch);
        });
    }).catch((error) => {
        dispatch(storeDispatch, firebaseRegister("FAILURE", error));
    });
}

export function sendVerificationMail(currentUser: any, storeDispatch: any) {
    currentUser
    .sendEmailVerification()
    .then(() => {
        dispatch(storeDispatch, firebaseVerification("SUCCESS"));
    })
    .catch((error: any) => {
        dispatch(storeDispatch, firebaseVerification("FAILURE", error));
    });
}
export function firebaseVerification(type?: string, response?: object):MiddlewareAction {
    return {actionName: `${Actions.FIREBASE_VERIFICATION_MAIL}${type ? `_${type}` : ""}`, body: {response}}
}
export function clearFirebaseResponse() {
    return {actionName: Actions.CLEARE_FIREBASE_RESPONSE}
}
export function sendResetPwEmail(email: string, storeDispatch: any) {
    dispatch(storeDispatch, firebaseVerification());
    firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
            dispatch(storeDispatch, firebaseVerification("SUCCESS"));
        })
        .catch((error) => {
            dispatch(storeDispatch, firebaseVerification("FAILURE", error));
        });
}
export function checkForAlreadyLogin(storeDispatch: any) {
    dispatch(storeDispatch, firebaseLogin());
    firebase.auth().onAuthStateChanged((user) => {
        if (user && user.emailVerified) {
            user.getIdToken().then(async (token) => {
                saveToken(token);
                (window as any).getToken = () => {
                    return token;
                };
                const user: LoggedInUserDetails = await dispatch(storeDispatch, getUser());
                setFirebaseUserProperty({ employerId: user.employerId });
                await dispatch(storeDispatch, firebaseLogin("SUCCESS"));
            });
        } else {
            dispatch(storeDispatch, stopLoading());
            // Auto login didn't happen
        }
    });
}
export function logout() {
    firebase
        .auth()
        .signOut()
        .then(() => {
            clearToken();
            window.location.href = webUrl;
        });
}
export function stopLoading() {
    return {actionName: Actions.STOP_LOADING}
}
export async function getIdToken(forceRefresh?: boolean) {
    const currentUser = firebase.auth().currentUser;
    return currentUser?.getIdToken(forceRefresh) || "";
}

export function getAllRoles() {
    return {
        actionName: Actions.REQUEST_ALL_ROLES,
        type: "GET",
        url: "/roles?userType=EMPLOYER_ADMIN",
    }
}