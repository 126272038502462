import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../reducers";
import dispatch from "../../../middleware";
import { isEmptyString } from "../../../utils";
import { AdminActivity, AdminTodo, AttendanceCycleDto, StatsDto } from "../../../dto";
import StatBox from "../../../components/stat-box ";
import { getAdminTodo } from "../../../actions/userInfo";
import Todo from "./todo";
import Activity from "./activity";
import Banner from "../../../images/illustration.svg";
import AllStats from "./allStats";
import getEffect from "../effect";
import { RoutePaths } from "../../../models";
import { getAttendanceCycle } from "../../../actions/attendance";
import TransactionSummary from "./transactionSummary";
import {getEmployerAdminActivities, getStats} from "../../../actions/employer";

export default function Home() {
  const storeDispatch = useDispatch();
  const employerId: string = useSelector(
    (state: ReduxState) => state.login.userDetails.employerId
  );
  const stats: StatsDto | null = useSelector(
    (state: ReduxState) => state.employer.stats
  );
  const adminTodo: AdminTodo[] | null = useSelector(
    (state: ReduxState) => state.login.adminTodo
  );
  const adminActivities: AdminActivity | null = useSelector(
    (state: ReduxState) => state.employer.adminActivities
  );
  const attendanceCycle: AttendanceCycleDto | null = useSelector(
    (state: ReduxState) => state.attendance.attendanceCycle
  );
  const fullName: string = useSelector(
    (state: ReduxState) => state.login.userDetails?.name || ""
  );
  function fetchActivities(status: boolean) {
    return dispatch(storeDispatch, getEmployerAdminActivities(status));
  }
  async function handleGetDashboardStats() {
    try {
      if (!stats) dispatch(storeDispatch, getStats(employerId));
      if (!adminTodo) dispatch(storeDispatch, getAdminTodo());
      if (!adminActivities) fetchActivities(true);
      if (!attendanceCycle) dispatch(storeDispatch, getAttendanceCycle());
    } catch (e) {}
  }

  useEffect(() => {
    if (!isEmptyString(employerId)) {
      handleGetDashboardStats();
    }
  }, [employerId]);
  useEffect(getEffect("Dashboard", useDispatch()), []);
  return (
    <div>
      <div className={"row my-3"}>
        <div className="col-xl-8">
          <div className="card p-4 intro">
            <h5 className="mb-2 fs-18">Hey {fullName}!</h5>
            <p className="mb-4 fs-14 fw-300">Please complete your todo list.</p>
            <img src={Banner} className="intro-icon" alt="banner" />
          </div>

          <div className="mt-4">
            <p className="table-title mb-2 fw-600 fs-18">Quick Stats</p>
          </div>
          <div className="row">
            <div className="col-xl-4 col-md-6 col-12">
              <StatBox
                label={"Total Employees"}
                value={stats ? `${stats.employees}` : null}
                redirectRoute={RoutePaths.EMPLOYEES}
              />
            </div>
            <div className="col-xl-4 col-md-6 col-12">
              <StatBox
                label={"Total Transactions"}
                value={stats ? `${stats.salaryWithdrawalCompleted}` : null}
                />
            </div>
            <div className="col-xl-4 col-md-6 col-12">
              <StatBox
                label={"Total Withdraw Value"}
                value={stats ? `₹ ${stats.withdrawalValue}` : null}
                redirectRoute={RoutePaths.EMPLOYERS_TRANSACTIONS}
              />
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col-lg-6">
              <AllStats stats={stats} />
            </div>
            <div className="col-lg-6 mt-lg-0 mt-4">
              <TransactionSummary attendanceCycle={attendanceCycle}/>
            </div>
            
          </div>
        </div>
        <div className="col-xl-4 mt-lg-0 mt-4">
          <Activity activities={adminActivities} onShowMoreClick={() => fetchActivities(false)} />

          <Todo todoList={adminTodo} />
        </div>
      </div>
    </div>
  );
}
