export enum FileType {
    IMG_PNG= "image/png",
    IMG_JPEG = "image/jpeg",
    APP_PDF = "application/pdf",
    UNKNOWN = "Unknown",
    EXCEL = "excel"
}

export const MAX_QTY_OF_RECENT_ACTIVITY = 10
export const MAX_MONTHLY_SALARY = 9_999_999;
export const DEFAULT_HTTP_REQUEST_TIMEOUT = 1 * 60 * 1000;
