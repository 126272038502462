import React, { useEffect, useState } from "react";
import { ReactComponent as ExcelIcon } from "./../../images/excel.svg";
import {
  downloadExcelFromBase64,
  getErrorMsgFromResponse,
  getMimeTypeOfUploadedFile,
  getParamFromUrl,
  isEmptyString,
  isIphoneDevice,
  navigateTo,
} from "../../utils";
import dispatch from "../../middleware";
import {
  addEmployeeInBulk,
  getEmployeeBulkUploadHistory,
  getEmployeeBulkUploadProcessedDoc,
  getEmployees,
  removeEmployeesInBulk,
} from "../../actions/employee";
import { showNotifier } from "../../actions/ui";
import {
  BulkEmployeeDocUploadHistory,
  FieldName,
  NotifierBgColor,
  NotifierType,
  RoutePaths,
} from "../../models";
import {
  listAttendance,
  uploadAttendanceInBulk,
} from "../../actions/attendance";
import { FileType } from "../../constants";
import { useDispatch } from "react-redux";
import {
  attendanceSampleSheetURL,
  employeeBulkUploadSampleSheetURL,
  payrollSampleSheetURL,
  removeEmployeesUploadSampleSheetURL,
  streamingAccountPayrollSampleSheetURL,
} from "../../config";
import FullScreenLoader from "../../components/fullscreen-loader";
import { listPayrolls, uploadPayroll } from "../../actions/payroll";
import getEffect from "../dashboard/effect";
import Authorized from "../../authorization/authorized";
import { AccessType, Resource } from "../../authorization/authorization.enum";
import { downloadProcessedPayrollFile } from "./helpers";
import AnalyticsEvent from "../../analytics/events";
import { logEventInFirebase } from "../../analytics/firebase.analytics";
import { PayrollStatusDto } from "../../dto";
import DocUploadHistoryTable, {
  DocumentMetadata,
  DocUploadHistoryRecord,
} from "./docUploadHistoryTable";

function isStreamingAccountService(docName: string | null) {
  return docName === DocumentType.PAYROLL_STREAMING_ACCOUNT;
}

enum DocumentType {
  BULK_ADD_EMP = "BULK_ADD_EMP",
  ATTENDANCE = "ATTENDANCE",
  BULK_TERMINATE = "BULK_TERMINATE",
  PAYROLL = "PAYROLL",
  PAYROLL_STREAMING_ACCOUNT = "PAYROLL_STREAMING_ACCOUNT",
}

const DOCUMENT_TYPE_TO_SAMPLE_URL_MAP: Record<DocumentType, string> = {
  [DocumentType.BULK_ADD_EMP]: employeeBulkUploadSampleSheetURL,
  [DocumentType.ATTENDANCE]: attendanceSampleSheetURL,
  [DocumentType.BULK_TERMINATE]: removeEmployeesUploadSampleSheetURL,
  [DocumentType.PAYROLL]: payrollSampleSheetURL,
  [DocumentType.PAYROLL_STREAMING_ACCOUNT]:
    streamingAccountPayrollSampleSheetURL,
};

const DOCUMENT_TYPE_TO_RESOURCE_MAP: Record<DocumentType, Resource> = {
  [DocumentType.BULK_ADD_EMP]: Resource.EMPLOYER_PORTAL_EMPLOYEES,
  [DocumentType.ATTENDANCE]: Resource.EMPLOYER_PORTAL_ATTENDANCE,
  [DocumentType.BULK_TERMINATE]: Resource.EMPLOYER_PORTAL_EMPLOYEES,
  [DocumentType.PAYROLL]: Resource.EMPLOYER_PORTAL_PAYROLL,
  [DocumentType.PAYROLL_STREAMING_ACCOUNT]: Resource.EMPLOYER_PORTAL_PAYROLL,
};

const DOCUMENT_TYPE_TO_UPLOAD_HISTORY_PROPS_MAP: Record<
  DocumentType,
  { title: string } | null
> = {
  [DocumentType.BULK_ADD_EMP]: { title: "Bulk Employee Upload History" },
  [DocumentType.ATTENDANCE]: null,
  [DocumentType.BULK_TERMINATE]: null,
  [DocumentType.PAYROLL]: null,
  [DocumentType.PAYROLL_STREAMING_ACCOUNT]: null,
};


function DocumentUpload() {
  const storeDispatch = useDispatch();
  const [file, setFile] = useState<File | null>(null);
  const [b64, setB64String] = useState("");
  const [loading, updateLoading] = useState(false);
  const [fileName, updateFileName] = useState("");
  const [docUploadHistoryRecords, setDocUploadHistoryRecords] = useState<
    DocUploadHistoryRecord[]
  >([]);

  const uploadType = getParamFromUrl("document") as DocumentType | null;
  const vendorId = getParamFromUrl("vendorId");
  const returnTo = getParamFromUrl("returnTo");
  const payrollMonth = getParamFromUrl("payrollMonth");

  const hasUploadHistory =
    !!uploadType && !!DOCUMENT_TYPE_TO_UPLOAD_HISTORY_PROPS_MAP[uploadType];

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) return;
    const file = e.target.files[0];
    setFile(file);
    // This hack to clear the selected file from <input /> elem
    e.target.value = "";
    // TODO: Refactor it
    getMimeTypeOfUploadedFile(file, isIphoneDevice())
      .then((fileType) => {
        if (fileType === FileType.EXCEL) {
          const fr = new FileReader();
          fr.onload = (evt) => {
            const b64Response = window.btoa((evt.target as any).result);
            setB64String(b64Response);
            updateFileName(file?.name || "");
          };
          fr.readAsBinaryString(file);
        } else {
          throw new Error("Only Excel file supported");
        }
      })
      .catch((e) => {
        dispatch(
          storeDispatch,
          showNotifier(
            "",
            "The document you uploaded is not in an acceptable format. Please reupload it in excel format.",
            NotifierType.ERROR,
            {
              title: "Format Error",
              autoClose: false,
            }
          )
        );
      });
  }

  async function handleUploadFile() {
    try {
      let resp = null;
      let fileName = "file";
      updateLoading(true);
      switch (uploadType) {
        case "BULK_ADD_EMP": {
          if (file === null) return;
          const formData = new FormData();
          formData.append("file", file);
          await dispatch(
            storeDispatch,
            addEmployeeInBulk(formData, vendorId)
          );
          // TODO: Think about un-mount cases
          setTimeout(fetchUploadHistory, 0);
          break;
        }
        case "ATTENDANCE":
          resp = await dispatch(
            storeDispatch,
            uploadAttendanceInBulk(b64, vendorId)
          );
          dispatch(storeDispatch, listAttendance());
          fileName = "bulk-upload-attendance-status";
          break;
        case "BULK_TERMINATE":
          resp = await dispatch(
            storeDispatch,
            removeEmployeesInBulk(b64, vendorId)
          );
          dispatch(storeDispatch, getEmployees(vendorId));
          fileName = "terminate-bulk-employee-status";
          break;
        case "PAYROLL":
          resp = await dispatch(storeDispatch, uploadPayroll(b64));
          dispatch(storeDispatch, listPayrolls());
          break;
        case "PAYROLL_STREAMING_ACCOUNT": {
          resp = await dispatch(storeDispatch, uploadPayroll(b64));
          break;
        }
        default:
      }
      logEventInFirebase(AnalyticsEvent.FILE_UPLOAD_SUCCESS, {
        doc_type: uploadType,
        vendorId,
      });
      if (isStreamingAccountService(uploadType)) {
        if (resp?.status === PayrollStatusDto.PROCESSING) {
          navigateTo(RoutePaths.PAYROLL);
          return;
        }
      } else {
        if (resp && resp.b64File) {
          downloadExcelFromBase64(resp.b64File, "bulk-upload-status");
        }
        updateFileName("");
        setB64String("");
        setFile(null);
        dispatch(
          storeDispatch,
          showNotifier(NotifierBgColor.SUCCESS, "File Uploaded")
        );
      }
      if (returnTo) {
        navigateTo(returnTo);
      }
    } catch (e) {
      logEventInFirebase(AnalyticsEvent.FILE_UPLOAD_FAILURE, {
        doc_type: uploadType,
        vendorId,
      });
      updateFileName("");
      setB64String("");
      setFile(null);
      if (isStreamingAccountService(uploadType)) {
        const b64File =
          (await downloadProcessedPayrollFile(storeDispatch)) || "";
        dispatch(
          storeDispatch,
          showNotifier("", getErrorMsgFromResponse(e), NotifierType.ERROR, {
            title: "Payroll Processing Failed",
            autoClose: false,
            actionButtonLabel: b64File ? "Download payroll sheet" : undefined,
            onActionButtonClick: () =>
              downloadExcelFromBase64(b64File, "PROCESSED_PAYROLL_SHEET"),
          })
        );
      } else {
        dispatch(
          storeDispatch,
          showNotifier(NotifierBgColor.ERROR, getErrorMsgFromResponse(e))
        );
      }
    }
    updateLoading(false);
  }

  async function fetchUploadHistory() {
    // TODO: Currently only for Bulk Employee upload, later implement others
    try {
      updateLoading(true);
      const resp: BulkEmployeeDocUploadHistory[] = await dispatch(
        storeDispatch,
        getEmployeeBulkUploadHistory(vendorId || undefined)
      );
      if (!Array.isArray(resp)) return;

      // Transform the response to required format;
      const uploadHistoryRecords = resp.map<DocUploadHistoryRecord>(
        (docUploadHistory) => ({
          originalDocument: {
            id: docUploadHistory.originalDocumentId,
            uri: docUploadHistory.originalDocumentURL,
            canDownload: true,
          },
          processedDocument: {
            id: docUploadHistory.processedDocumentId,
            uri: docUploadHistory.processedDocumentURL,
            canDownload:
              !!docUploadHistory.processedDocumentId &&
              !!docUploadHistory.processedDocumentURL &&
              docUploadHistory.status !== "PROCESSING",
          },
          uploadedAt: docUploadHistory.uploadedDate,
          uploadedBy: docUploadHistory.uploadedBy,
          status: docUploadHistory.status,
          employerAdminId: docUploadHistory.employerAdminId,
        })
      );
      setDocUploadHistoryRecords(uploadHistoryRecords);
    } catch (error) {
      // TODO: Handle error;
    } finally {
      updateLoading(false);
    }
  }

  async function handleProcessedFileDownload(docMetadata: DocumentMetadata) {
    if (!docMetadata.uri || !docMetadata.id) return;
    // TODO: Check if this works? What if anything is undefined?
    try {
      updateLoading(true);
      const [_, employerAdminId, documentType, documentId] =
        docMetadata.uri.split("/");
      const resp: { b64File: string } = await dispatch(
        storeDispatch,
        getEmployeeBulkUploadProcessedDoc({
          employerAdminId,
          documentType,
          documentId,
        })
      );
      // TODO: Check for one possible issue, browser's block multiple downloads via async click
      downloadExcelFromBase64(resp?.b64File, docMetadata.id);
    } catch (error) {
      // TODO: Handle
    } finally {
      updateLoading(false);
    }
  }

  function renderNotAccessible() {
    return (
      <div className={"d-flex justify-content-center align-items-center"}>
        You aren't authorized to access this page
      </div>
    );
  }
  function renderPayrollMonthText(payrollMonth: string | null) {
    if (!payrollMonth) return;
    return (
      <p>
        Payroll for the month of <b>{payrollMonth}</b>
      </p>
    );
  }
  function renderUploadHistory(
    docType: DocumentType,
    records: DocUploadHistoryRecord[],
    onDownloadClick: (
      docMetadata: DocumentMetadata,
      employerAdminId: string
    ) => void,
    onRefreshClick?: () => void
  ) {
    const uploadHistoryProps =
      DOCUMENT_TYPE_TO_UPLOAD_HISTORY_PROPS_MAP[docType];
    if (!uploadHistoryProps) return null;
    return (
      <DocUploadHistoryTable
        className="mt-4"
        {...uploadHistoryProps}
        records={records}
        originalDocDownloadCol={{ show: false }}
        processedDocDownloadCol={{ show: true, title: "Download" }}
        onDownloadClick={onDownloadClick}
        onRefreshClick={onRefreshClick}
      />
    );
  }

  useEffect(() => {
    if (hasUploadHistory) fetchUploadHistory();
    return getEffect("Document Upload", storeDispatch)();
  }, []);

  if (uploadType === null) return null;

  return (
    <React.Fragment>
      <FullScreenLoader active={loading} />
      <Authorized
        resourceName={DOCUMENT_TYPE_TO_RESOURCE_MAP[uploadType]}
        requiredAccessType={AccessType.WRITE}
        unAuthorizedView={renderNotAccessible()}
      >
        <div className={`doc-wrapper ${hasUploadHistory ? "doc-wrapper-with-history" : ""}`}>
          <div className="doc-upload-area">
            <p className="primary-color">Please upload the document</p>
            <div className="outline-btn mb-3">
              <p className="fs-12 primary-color m-0">
                Check sample format
                <span
                  className="fw-600 secondary-color mx-1 cursor-pointer"
                  onClick={() =>
                    window.open(
                      DOCUMENT_TYPE_TO_SAMPLE_URL_MAP[uploadType],
                      "_blank"
                    )
                  }
                >
                  here
                </span>
              </p>
            </div>
            {renderPayrollMonthText(payrollMonth)}
            <div className="upload-area" id={"upload-area"}>
              <ExcelIcon width="50" />
              <p
                className="m-0 fs-12 my-3 primary-color mb-1"
                style={{ wordBreak: "break-word" }}
              >
                {isEmptyString(fileName) ? "Please upload the file" : fileName}
              </p>
              <input
                type="file"
                onChange={handleFileChange}
                className={"display-none"}
                id={getParamFromUrl("document") || "file-id"}
              />
              <label
                htmlFor={getParamFromUrl("document") || "file-id"}
                className="btn primary-button m-0 fs-12  w-unset h-unset px-3 cursor-pointer"
              >
                Choose file
              </label>
            </div>
            <br />
            <button
              className="btn primary-button"
              onClick={handleUploadFile}
              disabled={b64 === ""}
            >
              Submit
            </button>
          </div>
          {renderUploadHistory(
            uploadType,
            docUploadHistoryRecords,
            handleProcessedFileDownload,
            fetchUploadHistory
          )}
        </div>
      </Authorized>
    </React.Fragment>
  );
}
export default DocumentUpload;
